import React from 'react'
import { Router, Switch, Route } from 'react-router-dom';
import history from '../lib/history';
import HomeContainer from '../components/home';
import TermsAndConditionContainer from '../components/termsAndCondition';
import PrivacyPolicyContainer from '../components/privacyPolicy';
import Notfound from '../components/notFound';
export default function AppRouter({ ...props }) {
    return (
        <Router history={history}>

            <Switch>

                <Route exact path="/" component={HomeContainer} />
                <Route exact path="/terms" component={TermsAndConditionContainer} />
                <Route exact path="/privacy" component={PrivacyPolicyContainer} />


                <Route component={Notfound} />
            </Switch>

        </Router>
    )
}
