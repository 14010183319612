import React from 'react'
import Layout from '../layout';
export default function HomeView({ ...props }) {
    return (
        <>
            <Layout>


                <section className="home-slider-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <h2>Teaching students and start-ups to code and bring their ideas to life</h2>
                                <a href="" className="btn btn-solid d-none d-md-block">Apply Today</a>
                            </div>
                            <div className="col-xl-5 d-block d-md-none mt-5">
                                <img src={process.env.PUBLIC_URL + "/images/top_img@2x.png"} className="img-fluid" alt="" />
                                <a href="" className="btn btn-solid mt-5">Apply Today</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="services-wrapper">
                    <div className="main-site-header text-center">
                        <h3>Our school includes:</h3>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <img src={process.env.PUBLIC_URL + "/images/icon_1.svg"} className="img-fluid mb-5" alt="" />
                                <p>Learn in-demand skills through a market-driven curriculum: HTML5, CSS3, JavaScript, jQuery, Express.js, React.js, Node.js, Progressive Web Apps, Database Theory, MongoDB, MySQL, Git, Python, Java, C#, PHP, and more.*</p>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <img src={process.env.PUBLIC_URL + "/images/icon_2.svg"} className="img-fluid mb-5" alt="" />
                                <p>Benefits of a wide range of career services to position you for success through graduation and beyond.</p>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <img src={process.env.PUBLIC_URL + "/images/icon_3.svg"} className="img-fluid mb-5" alt="" />
                                <p>Build a portfolio of web applications to showcase your knowledge.</p>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <img src={process.env.PUBLIC_URL + "/images/icon_4.svg"} className="img-fluid mb-5" alt="" />
                                <p>Study part-time, three days a week through our dynamic virtual classNameroom experience, while maintaining your work schedule.</p>
                            </div>
                        </div>
                    </div>
                </section>




                <section className="what-learn-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="main-site-header">
                                    <h3>What You Will Learn</h3>
                                </div>
                                <ul className="list-unstyled">
                                    <li>You will learn the skills for front end and back end development, developing fast and efficient full
                                        stack applicatios that simulate typical project you will experience early in your web development
                                        career.</li>
                                    <li>You will gain access to a tutor network, allowing you to get help both inside and outside the
                                        classNameroom.</li>
                                    <li>You will receive career-planning assistance, including:</li>
                                    <li>Portfolio reviews, resume and social media profile support, career content and practice sessions,
                                        technical interview training, 1:1 career coaching, soft skills training, and more.</li>
                                    <li>Access to a dedicated profile coach and career director that will work with you throughout the
                                        program and prepare you to be employer competitive.</li>
                                    <li>You will also gain industry insights through Tech Talks — quarterly online panels with local
                                        industry professionals.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 text-right mt-lg-0 mt-5">
                                <img src={process.env.PUBLIC_URL + "/images/will_learn.png"} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="row flex-md-row-reverse">
                            <div className="col-lg-6">
                                <div className="main-site-header">
                                    <h3>What You Will Receive</h3>
                                </div>
                                <ul className="list-unstyled">
                                    <li>You will benefit from Demo Day — a quarterly networking event with local
                                        professionals to showcase your final project.</li>
                                    <li>You will get access to an international alumni group of budding web
                                        developers.</li>
                                    <li>You will receive continued one-on-one career coaching with your career
                                        director post-graduation.</li>
                                    <li>You will gain a Certificate of Completion showcasing your accomplishments
                                        to future employers.</li>
                                    <li>You will have access to curated open-source projects and activities to help
                                        you demonstrate a commitment to continuous learning.</li>
                                    <li>You will receive supplemental online learning material to pick up additional indemand
                                        languages: Python, Java, C#, and PHP.</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 mt-lg-0 mt-5">
                                <img src={process.env.PUBLIC_URL + "/images/will_receive.png"} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>



                <section className="faq-wrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5">
                                <div className="main-site-header text-center">
                                    <h3>FAQ</h3>
                                </div>
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Do you provide career planning support?
                                                </button>
                                            </h2>
                                        </div>

                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the
                                                <code>.show</code> className.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Do you provide career planning support?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Some placeholder content for the second accordion panel. This panel is hidden by default.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Do you provide career planning support?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="card-body">
                                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by
                                                default.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingFour">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Do you provide career planning support?
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                            <div className="card-body">
                                                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by
                                                default.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="apply-form-wrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-9">
                                <div className="main-site-header text-center">
                                    <h3>Ready to Apply?</h3>
                                </div>
                                <div className="apply-form">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Company Name (if applicable)</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Message</label>
                                                <textarea name="" className="form-control" rows="6"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <a href="" className="btn btn-solid">Submit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </Layout>

        </>
    )
}
