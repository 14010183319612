import React from 'react'
import {
    Link,
} from "react-router-dom";
export default function Header() {
    return (
        <>
            <header className="main-header-wrapper">
                <div className="container">
                    <nav className="navbar navbar-expand-sm navbar-dark">
                        <Link to="/">
                            <a className="navbar-brand">
                                <img src={process.env.PUBLIC_URL + "/images/logo_white_bkg.png"} className="img-fluid" alt="" />
                            </a>
                        </Link>
                        <div className="ml-auto" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <Link to="/">
                                        <a className="nav-link" >Ready to start? </a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}
