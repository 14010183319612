import React from 'react'
import {
    Link,
} from "react-router-dom";
export default function Footer() {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <a className="navbar-brand" href="index.html">
                                <img src={process.env.PUBLIC_URL + "/images/logo_white_bkg.png"} className="img-fluid" alt="" />
                            </a>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    One to One Coding School 2021 &copy;
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/terms"><a href="terms-condition.html">Terms and Conditions</a></Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to="/privacy">  <a href="privacy-policy.html">Privacy Policy</a> </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
