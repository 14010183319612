import './css/App.css';
import AppRouter from './route'

function App({ ...props }) {
  return (

    <AppRouter />


  );
}

export default App;
